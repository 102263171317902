<template>
  <div class="record-details">
    <ul class="c-title">
      <li class="t-text">开票记录详情</li>
      <li>
        <el-button type="info" size="small" @click="$router.go(-1)">返回</el-button>
      </li>
    </ul>
    <el-card v-loading="loading">
      <el-descriptions title="订单信息" size="medium"> </el-descriptions>
      <el-table :data="recordList" style="width: 100%" :header-cell-style="{ 'text-align': 'center', color: '#aaa'  }"
        :cell-style="{ 'text-align': 'center', color: '#000' }" border
        >
        <el-table-column prop="id" label="ID" width="60"> </el-table-column>
        <el-table-column prop="order_num" label="订单号"> </el-table-column>
        <el-table-column prop="pay_type" label="支付方式">
          <template slot-scope="scope">
            <el-tag :type="scope.row.pay_type == 1 ? 'success' : 'primary'">
              {{ scope.row.pay_type | payTypeStatus }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="金额">
          <template slot-scope="scope">
            <div style="color: #ff7125">{{ scope.row.amount }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="时间"> </el-table-column>
      </el-table>
      <div style="margin:20px 0px">
        <el-descriptions title="开票状态" size="medium" :column="2" v-if="invoiceStatusInfo.status == 1" >
        <el-descriptions-item label="开票状态">
          {{ invoiceStatusInfo.status | invoiceStatus }}
        </el-descriptions-item>
        <el-descriptions-item label="发票编号">
          {{ invoiceStatusInfo.number }}
        </el-descriptions-item>
        <el-descriptions-item label="发票备注">
          {{ invoiceStatusInfo.remarks }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="开票状态" size="medium" :column="2" v-else >
        <el-descriptions-item label="开票状态">
          {{ invoiceStatusInfo.status | invoiceStatus }}
        </el-descriptions-item>
        <el-descriptions-item label="原因">
          {{ invoiceStatusInfo.reason }}
        </el-descriptions-item>
      </el-descriptions>
      </div>
    </el-card>
  </div>

</template>

<script>
  import {
    getRecordDetailsListApi
  } from '@/api/user'
  export default {
    name: 'recordInvoiceDetails',
    data() {
      return {
        id: '',
        recordList: [],
        loading: false,
        invoiceStatusInfo:{}
      }
    },
    filters: {
      payTypeStatus(val) {
        let msg = ""
        switch (val) {
          case 1:
            msg = "微信支付";
            break;
          case 2:
            msg = "支付宝支付";
            break;
        }
        return msg
      },
      invoiceStatus(val) {
        let msg = ""
        switch (val) {
          case 0:
            msg = "未开票";
            break;
          case 1:
            msg = "已开票";
            break;
          case 2:
            msg = "驳回";
            break;
            case 3:
            msg = "已取消";
            break;
        }
        return msg
      },
    },
    created() {
      this.id = this.$route.query.id
      this.getRecordList()
    },
    methods: {
      async getRecordList() {
        this.loading = true
        const {
          data: res
        } = await getRecordDetailsListApi(this.id)
        if (res.status != 10000) {
          return this.$message.error(res.message)
        }
        this.recordList = res.result.list
        this.invoiceStatusInfo=res.result
        this.loading = false
      }
    },
  }

</script>

<style lang="less" scoped>
  /deep/.el-descriptions__header {
    border-bottom: 1px solid #eee;
  }
  /deep/.el-descriptions__title{
    padding: 10px 0px;
  }

  .c-title {
      height: 40px;
      margin-bottom: 20px;
      border-bottom: 1px solid #e4e7ed;
      display: flex;
      justify-content: space-between;
      .t-text {
        line-height: 40px;
        font-size: 20px;
        font-weight: 500;
      }
    }

</style>
