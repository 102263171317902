import request from '@/libs/request'


/**
 * 打招呼列表数据
 */
export function getGreetListApi() {
    return request({
        url: '/greet/greet',
        method: 'get'
    })
}
/**
 * 新添打招呼列表数据
 */
export function addGreetListApi(data) {
    return request({
        url: '/greet/greet',
        method: 'post',
        data
    })
}
/**
 * 删除招呼列表数据
 */
export function deleteGreetListApi(id) {
    return request({
        url: `/greet/greet/${id}`,
        method: 'delete'
    })
}
/**
 * 提交公司信息
 */
export function sumbitCompanyInfoApi(data) {
    return request({
        url: '/company/addCompany',
        method: 'post',
        data
    })
}
/**
 * 回显公司信息
 */
export function showCompanyInfoApi() {
    return request({
        url: `/company/editCompany`,
        method: 'get',
    })
}
/**
 * 回显特许经营公司信息
 */
export function showFranchiseInfoApi() {
    return request({
        url: '/franchise/info',
        method: 'get',
    })
}
/**
 * 编辑招呼语回显
 */
export function showGreetListApi(id) {
    return request({
        url: `/greet/greet/${id}/edit`,
        method: 'get',
    })
}

/**
 * 修改招呼语提交
 */
export function editGreetListApi(data) {
    return request({
        url: `/greet/greet/${data.id}?content=${data.content}`,
        method: 'put',
    })
}

/**
 * 订单列表
 */
export function getOrderListApi(page, limit) {
    return request({
        url: `/company/order/list?page=${page}&limit=${limit}`,
        method: 'get',
    })
}

/**
 * 填写发票信息
 */
export function sumbitInvoiceInfoApi(data) {
    return request({
        url: '/company/invoice',
        method: 'post',
        data
    })
}

/**
 * 发票信息初始化
 */
export function getInvoiceInfoApi() {
    return request({
        url: '/company/invoiceInfo',
        method: 'get'
    })
}

/**
 * 申请开票
 */
export function applyInvoiceApi(data) {
    return request({
        url: 'company/invoicing',
        method: 'post',
        data
    })
}
/**
 * 发票记录列表页
 */
export function getRecordListApi(page, limit) {
    return request({
        url: `/company/recordList?page=${page}&limit=${limit}`,
        method: 'get',
    })
}
/**
 * 发票记录列表页
 */
export function cancleInvoiceApplyApi(id) {
    return request({
        url: `/company/cancel/${id}`,
        method: 'get',
    })
}
/**
 * 发票记录详情
 */
export function getRecordDetailsListApi(id) {
    return request({
        url: `/company/recordInfo/${id}`,
        method: 'get',
    })
}
export function getUserPhoneApi(id) {
    return request({
        url: `/company/getPhone/${id}`,
        method: 'get',
    })
}